import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/resources.scss"
import BlogGrid from "../components/BlogGrid"
import ResourcesGrid from "../components/ResourcesGridComponent"
import ButtonB from "../components/ButtonB"

// Page component
const Resources = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(edge => ({
    ...edge.node.frontmatter,
    url: edge.node.fields.slug,
  }))

  return (
    <Layout>
      <div className="blogs-bkg">
        <div className="charity-hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="inner-container">
                <div className="column">
                  <h1>Insights & Resources</h1>
                  <h2>
                    Read our latest blogs and stay up to date with everything
                    you need to know about your digital transformation journey.
                  </h2>
                  <div
                    className="blogs-hero-buttons"
                    style={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <ButtonB href={"#resources"}>Resources</ButtonB>
                    <ButtonB href={"#news"}>News</ButtonB>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="resources">
        <div className="section">
          <div className="outer-container">
            <div className="inner-container">
              <h1>Resources & Media</h1>
              <h2>Read our latest brochures and stay up to date</h2>
              <ResourcesGrid resources={resources} />
            </div>
          </div>
        </div>
      </div>

      <div id="news">
        <div className="section">
          <div className="outer-container">
            <div className="inner-container">
              <h1>News & Blogs</h1>
              <h2>Stay up to date with all things Metsi</h2>
              <BlogGrid posts={posts} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Resources

// GraphQL query to fetch blog posts
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            imageURL
            author
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const resources = [
  {
    title: "Nutanix PoV and User Journeys",
    imageURL: "/blog-images/User-Journeys.jpeg",
    imageAltText: "",
    tags: ["XaaS"],
    url: "/pdfs/Metsi flyer - Nutanix PoV development.pdf",
  },
  {
    title: "Automated Platform Provisioning powered by Merak",
    imageURL: "/blog-images/Merak.jpeg",
    imageAltText: "",
    tags: ["XaaS", "Automation"],
    url: "/pdfs/Metsi flyer - Platform Provisioning powered by Meraki (A4).pdf",
  },
  {
    title: "Accelerate Your Transition from VMware to Nutanix",
    imageURL: "/blog-images/Transition.jpeg",
    imageAltText: "",
    tags: ["Migration", "XaaS", "Automation"],
    url: "/pdfs/Metsi - Revolutionise Your Transition (Document (A4)).pdf",
  },
  {
    title: "Metsi AI Training",
    imageURL: "/blog-images/AITraining.jpeg",
    imageAltText: "",
    tags: ["Training"],
    url: "/pdfs/Metsi AI training.pdf",
  },
  {
    title: "Cisco Hyperflex to Nutanix Migration",
    imageURL: "/blog-images/nutanix.jpg",
    imageAltText: "",
    tags: ["Migration", "XaaS"],
    url:
      "/pdfs/_Metsi - Cisco Hyperflex to Nutanix Migration - Document (A4).pdf",
  },
  {
    title: "Maximising Your Service Now Platform Investment",
    imageURL: "/blog-images/servicenow.jpg",
    imageAltText: "XaaS",
    tags: ["Automation"],
    url: "/pdfs/_Metsi - Maximising Your ServiceNow Platform Investment.pdf",
  },
  {
    title: "Migration Case Study",
    imageURL: "/blog-images/migration.jpg",
    imageAltText: "",
    tags: ["Case Study", "Migration"],
    url: "/pdfs/Metsi Technologies - Migration Case Study.pdf",
  },
  {
    title: "Metsi Flyer",
    imageURL: "/blog-images/flyer.jpg",
    imageAltText: "",
    tags: ["XaaS"],
    url: "/pdfs/Metsi flyer 7.pdf",
  },
  {
    title: "Metsi Skills Overview",
    imageURL: "/blog-images/skills.jpg",
    imageAltText: "",
    tags: ["XaaS"],
    url: "/pdfs/Skills.pdf",
  },
  // {
  //   title: "Continuum Partnership",
  //   imageURL: "/blog-images/continuum.jpg",
  //   imageAltText: "",
  //   tags: ["Partners"],
  //   url: "/pdfs/ContinuumMetsi_Partnership.pdf",
  // },
  {
    title: "Secure your Hybrid Infrastructure",
    imageURL: "/blog-images/hybrid.png",
    imageAltText: "",
    tags: ["XaaS"],
    url: "/pdfs/Metsi flyer - New integration architecture.pdf",
  },
  {
    title: "XaaS and PoV-as-a-Service Generic Solution",
    imageURL: "/blog-images/xaas.jpg",
    imageAltText: "",
    tags: ["XaaS"],
    url: "/pdfs/Metsi flyer - XaaS-as-a-Service (A4).pdf",
  },
  {
    title: "Video 1",
    url: "https://www.youtube.com/watch?v=sexaLq6xya4",
    tags: ["Videos"],
  },
  {
    title: "Video 2",
    url: "https://www.youtube.com/watch?v=g80XLyT2LH8",
    tags: ["Videos"],
  },
  {
    title: "Video 3",
    url: "https://www.youtube.com/watch?v=lG581kqtcq0",
    tags: ["Videos"],
  },
  {
    title: "Video 4",
    url: "https://www.youtube.com/watch?v=eKQZcHD1l3k",
    tags: ["Videos"],
  },
  {
    title: "Video 5",
    url: "https://www.youtube.com/watch?v=sosyS7FAogg",
    tags: ["Videos"],
  },
]

import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  TextField,
} from "@mui/material"
import { BeatLoader } from "react-spinners"
import "../styles/resources.scss"

const predefinedTags = [
  "All",
  "News",
  "A.I",
  "Infrastructure",
  "App Modernisation",
  "FinOps",
  "Press Releases",
  "Job Vacancies",
]

const BlogGrid = ({ posts = [] }) => {
  const [selectedTag, setSelectedTag] = useState("All")
  const [searchQuery, setSearchQuery] = useState("")
  const [postsToShow, setPostsToShow] = useState([])
  const [visibleCount, setVisibleCount] = useState(6)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const filteredPosts =
      selectedTag === "All"
        ? posts
        : posts.filter(post => post.tags?.includes(selectedTag))

    const searchedPosts = filteredPosts.filter(
      post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (post.tags || []).some(tag =>
          tag.toLowerCase().includes(searchQuery.toLowerCase())
        )
    )

    setPostsToShow(searchedPosts.slice(0, visibleCount))
    setLoading(false)
  }, [selectedTag, searchQuery, posts, visibleCount])

  const loadMorePosts = () => {
    setVisibleCount(prevCount => prevCount + 3)
  }

  const truncateText = (text, length) => {
    if (text.length <= length) return text
    return text.substring(0, length) + "..."
  }

  return (
    <Container id="grid-blogs">
      <Box
        id="tag-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={2}
        gap={2}
      >
        <Box display="flex" flexWrap="wrap" gap={1}>
          {predefinedTags.map((tag, index) => (
            <Button
              key={index}
              variant={selectedTag === tag ? "contained" : "outlined"}
              onClick={() => setSelectedTag(tag)}
              sx={{
                margin: 0.5,
                borderRadius: 2,
                minWidth: "80px",
                textTransform: "capitalize",
                fontSize: "0.875rem",
                backgroundColor:
                  selectedTag === tag ? "#005b85" : "transparent",
                color: selectedTag === tag ? "#fff" : "#000",
                "&:hover": {
                  backgroundColor: selectedTag === tag ? "#004060" : "#e0e0e0",
                },
              }}
            >
              {tag}
            </Button>
          ))}
        </Box>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          onChange={e => setSearchQuery(e.target.value)}
          sx={{
            width: "200px",
          }}
        />
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <BeatLoader color="#1976d2" loading={loading} size={15} />
        </Box>
      ) : postsToShow.length === 0 ? (
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          style={{
            height: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedTag === "Job Vacancies"
            ? "No vacancies at the moment"
            : "No items to show for the selected filters"}
        </Typography>
      ) : (
        <Box
          className="grid-container"
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(280px, 1fr))"
          gap={4}
        >
          {postsToShow.map((post, index) => (
            <Card
              key={index}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: 2,
                boxShadow: 2,
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={post.imageURL}
                alt={post.title}
                sx={{ objectFit: "cover" }}
              />
              <CardContent>
                <Typography variant="subtitle2" color="textSecondary" mb={1}>
                  {(post.tags || []).join(", ")}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {truncateText(post.title, 50)}
                </Typography>
                <Typography variant="body2" color="textSecondary" mb={1}>
                  {post.author} | {post.date}
                </Typography>
                {/* <Typography variant="body2" paragraph>
                  {truncateText(post.description, 100)}
                </Typography> */}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  href={post.url}
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#005b85",
                    "&:hover": {
                      backgroundColor: "#004060",
                    },
                  }}
                >
                  Read More
                </Button>
              </CardActions>
            </Card>
          ))}
        </Box>
      )}
      {visibleCount < posts.length && postsToShow.length > 0 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            onClick={loadMorePosts}
            variant="contained"
            sx={{
              backgroundColor: "#005b85",
              "&:hover": {
                backgroundColor: "#004060",
              },
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Container>
  )
}

export default BlogGrid
